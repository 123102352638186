import { useQuery } from '@tanstack/react-query';
import { CreditsService, unwrapApiResponse } from '@salesport/api';

export const creditBalanceKeys = {
  all: ['credit-balance'] as const,
  get: () => [...creditBalanceKeys.all, 'get'] as const,
};

export function useCreditBalance() {
  const { data: creditBalance } = useQuery({
    queryKey: creditBalanceKeys.get(),
    queryFn: async () =>
      unwrapApiResponse(await CreditsService.creditsControllerGetUserCredits()),
    retry: false,
  });

  return creditBalance;
}
