'use client';

import { usePathname, useRouter } from 'next/navigation';

import {
  Button,
  Divider,
  Link,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Navbar as NavbarWrapper,
  PrimaryLightChip,
} from '@salesport/ui-components';

import Logo from '@/common/components/icons/Logo';
import { UserProfile } from '@/common/types';
import { handleRequest } from '@/common/utils/auth-helpers/client';
import { SignOut } from '@/common/utils/auth-helpers/server';
import { clientEnv } from '@/env/client-env';
import { useCreditBalance } from '@/features/balance';
import routes from '@/routes';
import { CogWheelIcon } from '../assets';
import { UserProfileDropdown } from '../UserProfileDropdown';

type NavbarProps = {
  user: UserProfile;
};
export const Navbar: React.FC<NavbarProps> = ({ user }) => {
  const creditBalance = useCreditBalance();
  return (
    <NavbarWrapper
      position="sticky"
      isBordered
      classNames={{
        item: 'data-[active=true]:text-primary ',
        wrapper: 'px-4 sm:px-6 max-w-full ',
      }}
      height="64px">
      <NavbarBrand>
        <Link href="/">
          <Logo />
        </Link>
      </NavbarBrand>
      <DesktopNavigation user={user} />
      <MobileNavigation creditBalance={creditBalance?.value} />

      <NavbarMenuToggle className="mr-2 h-6 sm:hidden" />
    </NavbarWrapper>
  );
};

type DesktopNavigationProps = {
  user: UserProfile;
};
const DesktopNavigation: React.FC<DesktopNavigationProps> = ({ user }) => {
  const accountUrl = routes.account.build();
  return (
    <NavbarContent className="hidden h-12 max-w-fit items-center gap-2 sm:flex">
      {/* Upgrade  */}
      <NavbarItem className="hidden sm:flex">
        <UpgradeButton />
      </NavbarItem>
      {/* Settings */}
      <NavbarItem className="hidden sm:flex">
        <Button isIconOnly radius="full" variant="light">
          <Link href={accountUrl}>
            <CogWheelIcon />
          </Link>
        </Button>
      </NavbarItem>
      {/* User Avatar with Dropdown */}
      <NavbarItem>
        <UserProfileDropdown user={user} />
      </NavbarItem>
    </NavbarContent>
  );
};

type MobileNavigationProps = {
  creditBalance?: number;
};
const MobileNavigation: React.FC<MobileNavigationProps> = ({
  creditBalance = 0,
}) => {
  const accountUrl = routes.account.build();
  const pricingUrl = routes.pricing.build();
  const helpPageUrl = clientEnv.spContactsUrl;
  return (
    <NavbarMenu>
      <NavbarMenuItem>
        <Link href="/" className="w-full" color="foreground">
          Dashboard
        </Link>
      </NavbarMenuItem>
      <NavbarMenuItem>
        <Link className="w-full" color="foreground" href={accountUrl}>
          Account Settings
        </Link>
      </NavbarMenuItem>
      <NavbarMenuItem>
        <Link className="w-full gap-4" color="foreground" href={pricingUrl}>
          Subscription
          <PrimaryLightChip>{creditBalance ?? 0} Credits</PrimaryLightChip>
        </Link>
      </NavbarMenuItem>
      <Divider />
      <NavbarMenuItem>
        <Link
          className="w-full"
          color="foreground"
          href={helpPageUrl}
          isExternal>
          Help & Information
        </Link>
      </NavbarMenuItem>
      <LogOutMenuItem />

      <NavbarMenuItem className="mt-3">
        <UpgradeButton fullWidth />
      </NavbarMenuItem>
    </NavbarMenu>
  );
};

const LogOutMenuItem: React.FC = () => {
  const router = useRouter();
  return (
    <form onSubmit={(e) => handleRequest(e, SignOut, router)}>
      {/* eslint-disable-next-line */}
      <input type="hidden" name="pathName" value={usePathname()} />
      <button type="submit">Sign out</button>
    </form>
  );
};

const UpgradeButton: React.FC<{ fullWidth?: boolean }> = ({
  fullWidth = false,
}) => {
  const pricingUrl = routes.pricing.build();
  const width = fullWidth ? 'w-full' : 'w-[138px]';
  return (
    <Button
      radius="full"
      className={`${width} bg-primary text-white shadow-md shadow-primary`}>
      <Link href={pricingUrl} size="sm" className="text-white">
        Upgrade
      </Link>
    </Button>
  );
};
